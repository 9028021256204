<template>
  <b-row>
    <b-col
      md="12"
      class="mb-1"
    >
      <b-button
        variant="primary"
        @click="$router.history.push({ name: 'add-company' })"
      >
        Add +
      </b-button>
    </b-col>
    <b-col md="12">
      <company-table
        :items="companies"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import * as companyService from '@/services/company'
import CompanyTable from './CompanyTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CompanyTable,
  },
  data() {
    return {
      companies: [],
    }
  },
  created() {
    this.getCompanies()
  },
  methods: {
    /** Get Table Data */
    getCompanies() {
      this.$store.commit('set_loading_action', true)
      companyService.getCompanies()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.companies = res.data.companies
        })
        .catch(() => {
          this.$store.commit('set_loading_action', false)
        })
    },
  },
}
</script>

<style>

</style>
